import React from 'react';
import * as XLSX from 'xlsx';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Button from '@material-ui/core/Button';
import { excelProcessData } from "../../utils/ExcelUtil";
import {Grid, Typography } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Navbar from './Navbar';
import styled from 'styled-components';
import Tabela from './Tabela';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { lookup } from '../../service/Lookup'
import { MenuItem } from '@mui/material';

class SearchInformation extends React.Component {
  state = {
    columns: [],
    uniqueData: {},
    data: [],
    allData: [],
    isUnique: false,
    searched: false,
    searchField: '',
    hostnameField: '',
    nsField: '',
    dmarkField: '',
    filterOpened: false
  }



  async searchUrl(url) {
    await lookup('').getByUrl(url).then(result => {
      console.log(result);
      this.setState({ allData: result, uniqueData: result, isUnique: true, searched: true });
    });
  }

  async clearFilter() {

    if (this.state.isUnique) {
      this.setState({ uniqueData: this.state.allData })
    }
    else {
      this.setState({ data: this.state.allData })
    }

    this.setState({ hostnameField: '', dmarkField: '', nsField: '',  })
  }

  async filterRows(hostname, dmark, ns) {

    console.log(hostname);

    const { uniqueData, isUnique, data, allData } = this.state;

    console.log(isUnique);

    if (isUnique) {
      return;

      if (hostname && uniqueData && !uniqueData.hostname.some(e => (e.includes(hostname)))) {
        this.setState({ uniqueData: {}, isUnique: true, searched: true });
      }
      if (dmark && uniqueData && uniqueData.dmarc.dmarcPublished !== dmark) {
        this.setState({ uniqueData: {}, isUnique: true, searched: true });
      }
      if (ns && uniqueData && !uniqueData.ns.some(v => (v.includes(ns)))) {
        this.setState({ uniqueData: {}, isUnique: true, searched: true });
      }
    }
    else {
      let filtered = allData;
      if (hostname) {
        filtered = data.filter(v => (v.hostname.some(e => (e.includes(hostname)))));
        console.log(filtered);
      }
      if (ns) {
        filtered = data.filter(v => (v.ns.some(e => (e.includes(ns)))));
        console.log(filtered);
      }
      if (dmark) {
        filtered = data.filter(v => (v.dmarc.dmarcPublished === dmark));
        console.log(filtered);
      }

      this.setState({ data: filtered, isUnique: false, searched: true });
    }
  }

  async handleFileUpload(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    let val = '';
    reader.onload = async (evt) => {
      /* Análise dos dados */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Obtendo a planilha */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Conversão de matrizes */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      val = excelProcessData(data);

      let body = { Urls: val }
      console.log(body);
      await lookup('').getByListUrl(body).then(result => {
        console.log(result);
        this.setState({ allData: result, data: result, isUnique: false, searched: true });
      });
    };

    reader.readAsBinaryString(file);
  }
  

  render() {

    const optionDmark = [
      {
        value: true,
        text:'Sim'
      },
      {
        value: false,
        text:'Não'
      },
    ];

    const handleClick = () => {
      this.setState({ filterOpened: !this.state.filterOpened });
    };

    const Input = styled('input')({
      display: 'none',
    });

    // Tratar o upload do arquivo

    return (
      <>
        <Navbar></Navbar>
        <Grid item xs={12} paddingX={30} >
          <div>

            <Grid container item xs={12} paddingY="20px"  >
              <Grid item xs={6}>
                <Typography paddingBottom={0.8} style={{ color: '#707070' }}>
                  Pesquisar Domínio
                </Typography>
                <Button onClick={() => this.searchUrl(this.state.searchField)} fullWidth>
                  <TextField
                    style={{ background: "#F9F9F9", border: "2px solid #DFDFDF", borderRadius: "10px", opacity: 1 }}
                    fullWidth
                    size="medium"
                    id="input-with-icon-textfield"
                    placeholder="Digite o domínio"
                    variant="outlined"
                    onChange={e => this.setState({ searchField: e.target.value })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon style={{ color: '#581C7F' }}></SearchIcon>
                        </InputAdornment>
                      ),
                    }}

                  />
                </Button>
              </Grid>
              <Grid item xs={4.8} paddingLeft={2} >
                <Typography paddingBottom={0.8} style={{ color: '#707070' }}>
                  Pesquisar por arquivo EXCEL
                </Typography>
                <Button fullWidth>
                  <TextField
                    style={{ background: "#F9F9F9", border: "2px solid #DFDFDF", borderRadius: "10px", opacity: '1', textAlign: "left", fontFamily: "Arial" }}
                    fullWidth
                    size="medium"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    placeholder="Faça o upload do arquivo"
                    textAlign="left"
                  />
                </Button>
              </Grid>
              <Grid item xs={1.2} paddingTop={4.5} paddingLeft={2} textAlign="right">
                <label htmlFor="contained-button-file">
                  <Input accept=".csv,.xlsx,.xls" id="contained-button-file" multiple type="file" onChange={e => this.handleFileUpload(e)} />
                  <Button variant="contained" component="span" style={{ backgroundColor: "#8F68DF", height:"57px" }}  fullWidth>
                    <Typography style={{ color: "#FFFFFF", opacity: 1 }}>
                      Upload
                    </Typography>
                  </Button>
                </label>
              </Grid>
            </Grid>

            <Grid item paddingBottom={3}>
              <List
                disablePadding
                style={{ width: '100%', bgcolor: '#FFFFFF', border: "2px solid #DFDFDF", borderRadius: "4px" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={handleClick} >
                  <ListItemText primary="Filtro" style={{ color: '#707070' }} />
                  {this.state.filterOpened ? <ExpandLess style={{ color: '#8F68DF' }} /> : <ExpandMore style={{ color: '#8F68DF' }} />}
                </ListItemButton>
                <Collapse in={this.state.filterOpened} timeout="auto" unmountOnExit  >
                  <List component="div" disablePadding>
                    <ListItem>
                      <Grid container item xs={12}>
                        <Grid item xs={4} paddingLeft={1.5}>
                          <Typography paddingBottom={0.8} style={{ color: '#707070', textAlign: "left" }}>
                            Hostname
                          </Typography>
                          <TextField
                            style={{ backgroundColor: "#F9F9F9", opacity: '1', border: '2px solid #DFDFDF', borderRadius: '10px' }}
                            fullWidth
                            size="small"
                            value={this.state.hostnameField}
                            onChange={e => this.setState({ hostnameField: e.target.value })}>

                          </TextField>
                        </Grid>
                        <Grid item xs={4} paddingLeft={1.5}>
                          <Typography paddingBottom={0.8} style={{ color: '#707070', textAlign: "left" }}>
                            Dmark Publicado
                          </Typography>
                          <TextField
                            select
                            style={{ backgroundColor: "#F9F9F9", opacity: '1', border: '2px solid #DFDFDF', borderRadius: '10px' }}
                            fullWidth
                            size="small"
                            value={this.state.dmarkField}
                            onChange={e => this.setState({ dmarkField: e.target.value })}>
                            {optionDmark.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.text}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={4} paddingLeft={1.5}>
                          <Typography paddingBottom={0.8} style={{ color: '#707070', textAlign: "left" }}    >
                            NS
                          </Typography>
                          <TextField
                            style={{ backgroundColor: "#F9F9F9", opacity: '1', border: '2px solid #DFDFDF', borderRadius: '10px' }}
                            fullWidth
                            size="small"
                            value={this.state.nsField}
                            onChange={e => this.setState({ nsField: e.target.value })}>

                          </TextField>
                        </Grid>
                      </Grid>
                    </ListItem>
                    <ListItem>
                      <Grid container item xs={12} justifyContent="flex-end">
                        <Grid >
                          <Button variant="contained" component="span" onClick={() => this.clearFilter()} color="inherit" style={{ backgroundColor: "#FFFFFF", border: "2px solid #581C7F", borderRadius: "4px", opacity: 1 }} >
                            <Typography style={{ color: "#581C7F", opacity: 1 }}>
                              Limpar
                            </Typography>
                          </Button>
                        </Grid>
                        <Grid paddingLeft={1.5}>
                          <Button variant="contained" component="span" onClick={() => this.filterRows(this.state.hostnameField, this.state.dmarkField, this.state.nsField)} style={{ backgroundColor: "#8F68DF", border: "2px solid #8F68DF", borderRadius: "4px", opacity: 1 }}>
                            <Typography style={{ color: "#FFFFFF", opacity: 1 }}>
                              Filtrar
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </Collapse >
              </List >
            </Grid>
          </div>

          <Tabela data={this.state.data} uniqueData={this.state.uniqueData} isUnique={this.state.isUnique} searched={this.state.searched}></Tabela>



        </Grid>

      </>
    );
  }
}

export default SearchInformation;