import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


class Tabela extends React.Component {

    createData(data) {
        return { dominio: data.domain, hostname: data.hostname, dmark: data.dmarc, dns: data.ns };
    }

    render() {
        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: '#511E72',
                border: '1px solid #FFFFFF',
                color: theme.palette.common.white,
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: 14,
                color: "#616161",
            },
        }));

        const StyledTableRow = styled(TableRow)(({ theme }) => ({
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
                border: '1px solid #FFFFFF',
            },
            // hide last border
            '&:last-child td, &:last-child th': {
                border: 1,
            },
        }));

        const rows = this.props.data ? this.props.data : [];
        const uniqueData = this.props.uniqueData ? this.props.uniqueData : null;
        
        let detail = <></>;

        if(this.props.searched && this.props.isUnique && uniqueData){
            detail = <><StyledTableRow key={uniqueData.domain}>
                        <StyledTableCell align="left">{uniqueData.domain}</StyledTableCell>
                        <StyledTableCell align="left">{uniqueData.hostname}</StyledTableCell>
                        <StyledTableCell align="left">
                                        Publicado: {uniqueData.dmarc.dmarcPublished ? 'Sim' : 'Não'}
                                        <br></br>
                                        Habilitado: {uniqueData.dmarc.dmarcPolicyEnable ? 'Sim' : 'Não'}
                                        <br></br>
                                        Política: {uniqueData.dmarc.dmarcPolicy}
                        </StyledTableCell>
                        <StyledTableCell align="left">{uniqueData.ns.map((n) => (
                            <>
                               {n} 
                               <br></br>
                            </>
                        ))}</StyledTableCell>
                    </StyledTableRow></>;
        }
        else if(this.props.searched && rows.length > 0)
        {
            detail = rows.map((row) => (
                <StyledTableRow key={row.domain}>
                    <StyledTableCell align="left">{row.domain}</StyledTableCell>
                    <StyledTableCell align="left">{row.hostname}</StyledTableCell>
                    <StyledTableCell align="left">
                                    Publicado: {row.dmarc.dmarcPublished ? 'Sim' : 'Não'}
                                    <br></br>
                                    Habilitado: {row.dmarc.dmarcPolicyEnable ? 'Sim' : 'Não'}
                                    <br></br>
                                    Política: {row.dmarc.dmarcPolicy}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.ns.map((n) => (
                        <>
                            {n} 
                            <br></br>
                        </>
                    ))}</StyledTableCell>
                </StyledTableRow>
            ));
        }

        return (
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" >
                        <TableHead >
                            <TableRow >
                                <StyledTableCell align="left">Domínio</StyledTableCell>
                                <StyledTableCell align="left">Hostname</StyledTableCell>
                                <StyledTableCell align="left">Dmark</StyledTableCell>
                                <StyledTableCell align="left">DNS</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {detail}
                        </TableBody>
                    </Table>
                </TableContainer>

        );
    }
}
export default Tabela;