import React from 'react';
import SearchInformation from './pages/home/SearchInformation';


function App() {
  
  return (
    <SearchInformation></SearchInformation>
  );
}
export default App;
