export default class DataManager{
    static myInstance = null;

  _env = 'PROD';

  /**
   * @returns {DataManager}
   */
  static getInstance() {

    if (DataManager.myInstance == null) {
      DataManager.myInstance = new DataManager();
    }

    return this.myInstance;
  }

  getHost() {
    if (this._env === 'PROD') {
      return 'https://discoverydns-api.venhapranuvem.com.br';
    } else {
      return 'http://localhost:12349';
    }
  }

  getEnv() {
    return this._env;
  }

  setEnv(env) {
    this._env = env;
  }
}