import {ApiClient} from './Api';

export function lookup(userToken) {

  async function getByUrl(url) {
    try {
      const client = new ApiClient(userToken);
      const urlFull = 'LookUp/Url/' + url;

      const call = await client.retrieve(urlFull);

      console.log(call);

      return call;

    } catch (e) {
      console.error(e);
    }
  };

  async function getByListUrl(list) {
    try {
      const client = new ApiClient(userToken);
      const urlFull = 'LookUp';

      const call = await client.create(urlFull, list);

      console.log(call);

      return call;

    } catch (e) {
      console.error(e);
    }
  };

  return {
    getByUrl: getByUrl,
    getByListUrl: getByListUrl
  }
}