import DataManager from '../DataManager';
import withQuery from 'with-query';


class ApiClient {

  constructor(token) {
    this.token = token;
    this.appDataManager = DataManager.getInstance();
    this.baseUrl = `${this.appDataManager.getHost()}/api`;
  }

  get headers() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      Authorization: this.token,
    };
  }

  request = async (method, path, queryParams = {}, body = null) => {

    const url = withQuery(`${this.baseUrl}/${path}`, queryParams);

    const requestOptions = {
      method: method,
      headers: this.headers,
    };

    if (body != null) {
      requestOptions.body = JSON.stringify(body);
    }

    console.log(requestOptions);

    const response = await fetch(url, requestOptions);
    return await response.json();
  };

  create = async (path, body, queryParams = {}) => {
    return await this.request('POST', path, queryParams, body);
  };

  retrieve = async (path, queryParams = {}) => {
    return await this.request('GET', path, queryParams);
  };

  update = async (path, body, queryParams = {}) => {
    return await this.request('PUT', path, queryParams, body);
  };

  delete = async (path, body = {}, queryParams = {}) => {
    return await this.request('DELETE', path, queryParams, body);
  };

  partialUpdate = async (path, body, queryParams = {}) => {
    return await this.request('PATCH', path, queryParams, body);
  };
}

export {ApiClient};