import React from 'react';
import { AppBar, Grid, Typography } from '@material-ui/core';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import logo from './portal_vpn.png'

class Navbar extends React.Component {

  render() {
    return (
      <>
        <AppBar position="static" style={{ backgroundColor: "#581C7F" }} >
          <Toolbar variant="dense" >
            <Grid container item xs={12}>
              <Grid container item xs={5}>
                <Grid item xs={0.8}> 
                  <IconButton edge="start" color="inherit" aria-label="menu" >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4.2} paddingTop={0.5}>
                  <img src={logo} alt="Logo"/>
                </Grid>
              </Grid>
              <Grid container item xs={7}>
                <Grid item xs={4}>
                  <Typography textAlign="center" variant="h6" color="#FFFFFF">
                    VPN Discovery
                  </Typography>
                </Grid>
                <Grid item xs={3} justifyContent="flex-end" paddingLeft={88}>
                  <IconButton edge="end" color="inherit" aria-label="menu"  >
                    <PersonIcon
                    ></PersonIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}
export default Navbar;